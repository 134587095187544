import { useState } from 'react'

import * as S from './styles'
import * as Txt from '../../styles/text'
import AdminTrabalheCardEdit from '../AdminTrabalheCardEdit'

interface TrabalheProps {
  id: string
  nome_vaga: string
  desc_vaga: string
  req_vaga: string
  salario: string
}

const AdminTrabalheCard = ({
  id,
  nome_vaga,
  desc_vaga,
  req_vaga,
  salario
}: TrabalheProps) => {
  const [isEditing, setIsEditing] = useState(false)

  return isEditing ? (
    <AdminTrabalheCardEdit
      id={id}
      nome_vaga={nome_vaga}
      desc_vaga={desc_vaga}
      req_vaga={req_vaga}
      salario={salario}
      isEditing={isEditing}
      setIsEditing={setIsEditing}
    />
  ) : (
    <>
      <S.AdminVagaCard>
        <Txt.TitleBig className="text-center">{nome_vaga}</Txt.TitleBig>
        <Txt.TitleMd className="text-center">Descrição</Txt.TitleMd>
        <Txt.TextMd className="text-center">{desc_vaga}</Txt.TextMd>
        <Txt.TitleMd className="text-center">Requisitos</Txt.TitleMd>
        <Txt.TextMd className="text-center">{req_vaga}</Txt.TextMd>
        <Txt.TitleMd className="text-center">Salario</Txt.TitleMd>
        <Txt.TextMd className="text-center">{salario}</Txt.TextMd>
        <div>
          <S.EditBtn
            type="button"
            className="btn btn-warning w-100"
            onClick={() => setIsEditing(!isEditing)}
          >
            Editar
          </S.EditBtn>
        </div>
      </S.AdminVagaCard>
    </>
  )
}

export default AdminTrabalheCard
