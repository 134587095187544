import { useEffect, useState } from 'react'
import * as S from './styles'

interface Sobre {
  id: string
  sobre_escola: string
  missao: string
  visao: string
  valores: string
}

const AdminSobreForms = () => {
  const [sobre, setSobre] = useState<Sobre | null>(null)
  const [formValues, setFormValues] = useState({
    sobre_escola: '',
    missao: '',
    visao: '',
    valores: ''
  })
  const [error, setError] = useState<string | null>(null)

  const fetchSobre = async () => {
    try {
      const response = await fetch('http://localhost:8000/api/sobre/')
      if (!response.ok) {
        throw new Error('Erro ao carregar os dados')
      }
      const [data] = await response.json() // Supondo que o endpoint retorne uma lista
      setSobre(data)
      setFormValues({
        sobre_escola: data.sobre_escola || '',
        missao: data.missao || '',
        visao: data.visao || '',
        valores: data.valores || ''
      })
    } catch (err: any) {
      setError(err.message)
    }
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = e.target
    setFormValues((prev) => ({ ...prev, [name]: value }))
  }

  const handleSave = async () => {
    if (!sobre) return
    try {
      const response = await fetch(
        `http://localhost:8000/api/sobre/${sobre.id}/`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(formValues)
        }
      )

      if (!response.ok) {
        throw new Error('Erro ao salvar os dados')
      }

      const updatedSobre = await response.json()
      setSobre(updatedSobre)
      alert('Alterações salvas com sucesso!')
    } catch (error: any) {
      console.error(error)
      alert('Erro ao salvar as alterações.')
    }
  }

  useEffect(() => {
    fetchSobre()
  }, [])

  return (
    <form>
      <div>
        <h1 className="text-center text-white mt-3 mb-3">Sobre a escola</h1>
      </div>
      <S.SobreTextarea
        name="sobre_escola"
        className="form-control"
        value={formValues.sobre_escola}
        onChange={handleInputChange}
      />
      <div>
        <h1 className="text-center text-white mt-3 mb-3">Missão</h1>
      </div>
      <S.SobreTextarea
        name="missao"
        className="form-control"
        value={formValues.missao}
        onChange={handleInputChange}
      />
      <div>
        <h1 className="text-center text-white mt-3 mb-3">Visão</h1>
      </div>
      <S.SobreTextarea
        name="visao"
        className="form-control"
        value={formValues.visao}
        onChange={handleInputChange}
      />
      <div>
        <h1 className="text-center text-white mt-3 mb-3">Valores</h1>
      </div>
      <S.SobreTextarea
        name="valores"
        className="form-control"
        value={formValues.valores}
        onChange={handleInputChange}
      />
      <button
        type="button"
        className="btn btn-success w-100 mt-3"
        onClick={handleSave}
      >
        Salvar mudanças
      </button>
    </form>
  )
}

export default AdminSobreForms
