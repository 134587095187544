import styled from 'styled-components'
import { cores } from '../../styles'

export const FormTurma = styled.form`
  background-color: ${cores.corPrincipal};
  padding: 24px;
  border-radius: 8px;
`

export const InputTitle = styled.input`
  color: #000;
`

export const FormLabel = styled.label`
  color: #000;
`

export const HiddenContainer = styled.div`
  .hidden {
    display: none;
  }
`
