import {
  FaInstagram,
  FaMapMarkerAlt,
  FaEnvelope,
  FaPhoneSquare,
  FaFacebook
} from 'react-icons/fa'

import * as S from './styles'
import * as Global from '../../styles/index'
import * as Txt from '../../styles/text'
import { useEffect, useState } from 'react'

interface Contato {
  id: string
  tel: string
  endereco: string
  endereco_link: string
  email: string
  facebook_link: string
  instagram_link: string
}

const Contato = () => {
  const [contato, setContato] = useState<Contato | null>(null)
  const [contatoValues, setContatoValues] = useState({
    tel: '',
    endereco: '',
    endereco_link: '',
    email: '',
    facebook_link: '',
    instagram_link: ''
  })
  const [error, setError] = useState<string | null>(null)

  const fetchContato = async () => {
    try {
      const response = await fetch('http://localhost:8000/api/contato/')
      if (!response.ok) {
        throw new Error(
          'Não foi possível requisitar os dados de localhost:8000/api/contato/'
        )
      }
      const data = await response.json()
      const contatoData = Array.isArray(data) ? data[0] : data
      setContato(contatoData)
      setContatoValues({
        tel: contatoData.tel || '',
        endereco: contatoData.endereco || '',
        endereco_link: contatoData.endereco_link || '',
        email: contatoData.email || '',
        facebook_link: contatoData.facebook_link || '',
        instagram_link: contatoData.instagram_link || ''
      })
    } catch (err: any) {
      setError(err.message)
    }
  }

  useEffect(() => {
    fetchContato()
  }, [])

  if (!contato) {
    return <p>Carregando info de contato</p>
  }

  return (
    <>
      <S.FormContainer id="contato">
        <Global.Container>
          <Txt.TitleBig>Contato</Txt.TitleBig>
          <div>
            <S.ListContainer>
              <li>
                <S.ListItemBlock>
                  <S.ListIconBlock>
                    <FaPhoneSquare size={30} />
                  </S.ListIconBlock>
                  <a href={`tel:${contato.tel}`}>
                    <Txt.TextMd>{contato.tel}</Txt.TextMd>
                  </a>
                </S.ListItemBlock>
              </li>
              <li>
                <S.ListItemBlock>
                  <S.ListIconBlock>
                    <FaMapMarkerAlt size={30} />
                  </S.ListIconBlock>
                  <a href={`${contato.endereco_link}`}>
                    <Txt.TextMd>{contato.endereco}</Txt.TextMd>
                  </a>
                </S.ListItemBlock>
              </li>
              <li>
                <S.ListItemBlock>
                  <S.ListIconBlock>
                    <FaEnvelope size={30} />
                  </S.ListIconBlock>
                  <a href={`mailto:${contato.email}`}>
                    <Txt.TextMd>{contato.email}</Txt.TextMd>
                  </a>
                </S.ListItemBlock>
              </li>
              <li>
                <S.ListItemFlex>
                  <a href={contato.facebook_link} className="flex-icon">
                    <S.ListSocialIcon>
                      <FaFacebook size={35} className="margin" />
                    </S.ListSocialIcon>
                  </a>
                  <a href={contato.instagram_link}>
                    <S.ListSocialIcon>
                      <FaInstagram size={35} />
                    </S.ListSocialIcon>
                  </a>
                </S.ListItemFlex>
              </li>
            </S.ListContainer>
          </div>
        </Global.Container>
      </S.FormContainer>
    </>
  )
}

export default Contato
