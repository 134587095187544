import AdminTopMenu from '../../components/AdminTopMenu'
import AdminMenu from '../../components/AdminMenu'
import AdminTrabalheList from '../../containers/AdminTrabalheList'

import * as S from './styles'

const AdminTrabalhe = () => {
  return (
    <>
      <S.bgAdmin>
        <AdminTopMenu title="Trabalhe conosco" />
        <AdminMenu />
        <AdminTrabalheList />
      </S.bgAdmin>
    </>
  )
}

export default AdminTrabalhe
