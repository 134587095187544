import { useEffect, useState } from 'react'
import { BsChevronUp, BsChevronDown, BsFillTrash3Fill } from 'react-icons/bs'

import * as Txt from '../../styles/text'
import * as S from './styles'

import AdminTurmaAtivForm from '../AdminTurmaAtivForm'

interface Atividades {
  id: string
  titulo_ativ: string
  desc_ativ: string
  posicao: number
  turma: string
}

interface Turmas {
  id: string
  atividades: Atividades[]
  nome_turma: string
  nome_formal: string
  idade_turma: string
  objetivo: string
  desenvolver: string
  posicao: number
  imagem: string
}

const AdminTurmaCard = () => {
  const [turmas, setTurmas] = useState<Turmas[]>([])
  const [turmasError, setTurmasError] = useState<string | null>(null)
  const [error, setError] = useState<string | null>(null)

  const [imagem, setImagem] = useState<File | null>(null)
  const [expandTurmaId, setExpandTurmaId] = useState<string | null>(null)
  const [atividadeEditando, setAtividadeEditando] = useState<Atividades | null>(
    null
  )
  const [atividadeEditandoId, setAtividadeEditandoId] = useState<string | null>(
    null
  )

  const [formAtividades, setFormAtividades] = useState({
    id: '',
    titulo_ativ: '',
    desc_ativ: '',
    posicao: 0,
    turma: ''
  })

  const [formTurma, setFormTurma] = useState<Turmas>({
    id: '',
    atividades: [],
    nome_turma: '',
    nome_formal: '',
    idade_turma: '',
    objetivo: '',
    desenvolver: '',
    posicao: 0,
    imagem: ''
  })

  const [toggleIsCollapsed, setToggleIsCollapsed] = useState<boolean>(true)
  const [toggleIsEditing, setToggleIsEditing] = useState<
    Record<string, boolean>
  >({})

  const toggleEditing = (turmaId: string) => {
    setToggleIsEditing((prev) => ({
      ...prev,
      [turmaId]: !prev[turmaId]
    }))
  }

  const seedFormAtividades = (atividade: Atividades) => {
    setFormAtividades({
      id: atividade.id || '',
      titulo_ativ: atividade.titulo_ativ || '',
      desc_ativ: atividade.desc_ativ || '',
      posicao: atividade.posicao || 0,
      turma: atividade.turma || ''
    })
  }

  const seedFormTurma = (turma: Turmas) => {
    setFormTurma({
      id: turma.id || '',
      atividades: turma.atividades as Atividades[],
      nome_turma: turma.nome_turma || '',
      nome_formal: turma.nome_formal || '',
      idade_turma: turma.idade_turma || '',
      objetivo: turma.objetivo || '',
      desenvolver: turma.desenvolver || '',
      posicao: turma.posicao || 0,
      imagem: turma.imagem || ''
    })
  }

  const handleInputChangeTurma = (
    e: React.ChangeEvent<HTMLInputElement | HTMLInputElement>
  ) => {
    const { name, value } = e.target
    setFormTurma((prev) => ({ ...prev, [name]: value }))
  }

  const handleTextAreaChangeTurma = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target
    setFormTurma((prev) => ({ ...prev, [name]: value }))
  }

  const handleInputChangeAtividade = (
    e: React.ChangeEvent<HTMLInputElement | HTMLInputElement>
  ) => {
    const { name, value } = e.target
    setFormAtividades((prev) => ({ ...prev, [name]: value }))
  }

  const handleTextAreaChangeAtividade = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target
    setFormAtividades((prev) => ({ ...prev, [name]: value }))
  }

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setImagem(e.target.files[0])
    }
  }

  const toggleExpand = (id: string) => {
    setExpandTurmaId((prevId) => (prevId === id ? null : id))
  }

  const fetchTurmas = async () => {
    try {
      const response = await fetch('http://localhost:8000/api/turmas/')
      if (!response.ok) {
        throw new Error(
          'Não foi possível requisitar os dados de localhost:8000/api/turmas/'
        )
      }
      const data = await response.json()
      setTurmas(data)
    } catch (err: any) {
      setTurmasError(err.message)
    }
  }

  const handlePutTurma = async (id: string) => {
    try {
      const formData = new FormData()
      formData.append('atividades', JSON.stringify(formTurma.atividades))
      formData.append('nome_turma', formTurma.nome_turma)
      formData.append('nome_formal', formTurma.nome_formal)
      formData.append('idade_turma', formTurma.idade_turma)
      formData.append('objetivo', formTurma.objetivo)
      formData.append('desenvolver', formTurma.desenvolver)

      // Se estiver enviando uma imagem, adicione-a aqui
      if (imagem) {
        formData.append('imagem', imagem)
      }

      const response = await fetch(`http://localhost:8000/api/turmas/${id}/`, {
        method: 'PUT',
        body: formData
      })

      if (!response.ok) {
        const errorData = await response.json()
        console.error('Erro na resposta:', errorData)
        setError(
          `Erro ao atualizar: ${errorData.detail || 'Falha desconhecida'}`
        )
        return
      }

      setFormTurma({
        id: '',
        atividades: [],
        nome_turma: '',
        nome_formal: '',
        idade_turma: '',
        objetivo: '',
        desenvolver: '',
        posicao: 0,
        imagem: ''
      })

      window.location.reload()
    } catch (error) {
      console.error('Erro na requisição:', error)
      setError('Ocorreu um erro ao atualizar a turma. Verifique sua conexão.')
    }
  }

  const handlePutAtividade = async (id: string) => {
    try {
      const response = await fetch(
        `http://localhost:8000/api/atividades/${id}/`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            titulo_ativ: formAtividades.titulo_ativ,
            desc_ativ: formAtividades.desc_ativ,
            posicao: formAtividades.posicao,
            turma: formAtividades.turma
          })
        }
      )

      if (!response.ok) {
        const errorData = await response.json()
        console.error('Erro na resposta:', errorData)
        setError(
          `Erro ao atualizar: ${errorData.detail || 'Falha desconhecida'}`
        )
        return
      }

      setFormAtividades({
        id: '',
        titulo_ativ: '',
        desc_ativ: '',
        posicao: 0,
        turma: ''
      })

      window.location.reload()
    } catch (error) {
      console.error('Erro na requisição:', error)
      setError('Ocorreu um erro ao atualizar a turma. Verifique sua conexão.')
    }
  }

  const deleteAtividade = async (id_atividade: string) => {
    try {
      const response = await fetch(
        `http://localhost:8000/api/atividades/${id_atividade}/`,
        {
          method: 'DELETE'
        }
      )
      if (!response.ok) {
        throw new Error(
          `Erro ao deletar a atividade da API em localhost:8000/api/atividades/${id_atividade}`
        )
      }
      alert('Atividade excluída com sucesso!')
      window.location.reload()
    } catch (err: any) {
      setError(err.message)
    }
  }

  useEffect(() => {
    fetchTurmas()
  }, [])

  useEffect(() => {
    const editingTurmaId = Object.keys(toggleIsEditing).find(
      (key) => toggleIsEditing[key]
    )
    if (editingTurmaId) {
      const editingTurma = turmas.find((turma) => turma.id === editingTurmaId)
      if (editingTurma) {
        seedFormTurma(editingTurma)

        if (atividadeEditando) {
          console.log('Seeding atividade:', atividadeEditando) // Verifica qual atividade está sendo seedada
          seedFormAtividades(atividadeEditando)
        } else if (editingTurma.atividades.length > 0) {
          const primeiraAtividade = editingTurma.atividades[0]
          setAtividadeEditando(primeiraAtividade) // Define a primeira atividade
          seedFormAtividades(primeiraAtividade) // Seeda a primeira atividade
        }
      }
    }
  }, [toggleIsEditing, turmas, atividadeEditando])

  return (
    <>
      {turmas.map((turma) => {
        const isExpanded = expandTurmaId === turma.id
        const isEditing = toggleIsEditing[turma.id] || false

        return (
          <S.TurmaCard key={turma.id}>
            <S.TurmaCardHeader>
              <S.TitleContainer>
                <Txt.TitleMd>
                  {isEditing
                    ? `Editando: ${turma.nome_turma}`
                    : `${turma.nome_turma} - ${turma.nome_formal} (${turma.idade_turma})`}
                </Txt.TitleMd>
              </S.TitleContainer>
              <S.ToggleButtonContainer>
                {isExpanded ? (
                  <BsChevronUp
                    size={40}
                    onClick={() => toggleExpand(turma.id)}
                  />
                ) : (
                  <BsChevronDown
                    size={40}
                    onClick={() => toggleExpand(turma.id)}
                  />
                )}
              </S.ToggleButtonContainer>
            </S.TurmaCardHeader>

            <S.TurmaCardBody className={isExpanded ? 'expanded' : 'collapsed'}>
              <S.ImageContainer>
                <img src={turma.imagem} alt="" />
              </S.ImageContainer>
              <div className={isEditing ? '' : 'hidden'}>
                <form action="">
                  <p className="mt-4 text-center">Escolher nova imagem</p>
                  <input
                    type="file"
                    name="imagem-turma"
                    id="imagem-turma"
                    className="form-control"
                    onChange={handleFileChange}
                  />
                  <div className="form-floating mb-2">
                    <input
                      type="text"
                      className="form-control mt-4"
                      id="nome-turma"
                      name="nome_turma"
                      placeholder="Nome da turma"
                      value={formTurma.nome_turma}
                      onChange={handleInputChangeTurma}
                    />
                    <label htmlFor="nome-turma">Nome da turma</label>
                  </div>
                  <div className="form-floating mb-2">
                    <input
                      type="text"
                      className="form-control mt-4"
                      id="nome-forma"
                      name="nome_formal"
                      placeholder="Nome da turma"
                      value={formTurma.nome_formal}
                      onChange={handleInputChangeTurma}
                    />
                    <label htmlFor="nome-turma">Nome formal da turma</label>
                  </div>
                  <div className="form-floating mb-3">
                    <input
                      type="text"
                      className="form-control mt-3"
                      id="idade-alunos"
                      name="idade_turma"
                      placeholder="Idade dos alunos"
                      value={formTurma.idade_turma}
                      onChange={handleInputChangeTurma}
                    />
                    <label htmlFor="idade-alunos">Idade dos alunos</label>
                  </div>
                  <div className="form-floating mt-3 mb-3">
                    <textarea
                      className="form-control"
                      placeholder="Objetivo principal"
                      id="objetivo-turma"
                      name="objetivo"
                      value={formTurma.objetivo}
                      onChange={handleTextAreaChangeTurma}
                    />
                    <label htmlFor="objetivo-turma">
                      {'Objetivo principal'}
                    </label>
                  </div>
                  <div className="form-floating mt-3 mb-3">
                    <textarea
                      className="form-control"
                      placeholder="O que será desenvolvido"
                      id="desenvolver-turma"
                      name="desenvolver"
                      value={formTurma.desenvolver}
                      onChange={handleTextAreaChangeTurma}
                    />
                    <label htmlFor="desenvolver-turma">
                      {'O que será desenvolvido'}
                    </label>
                  </div>
                  <S.LabelContainer>
                    <Txt.TitleMd>Atividades típicas</Txt.TitleMd>
                  </S.LabelContainer>
                  {turma.atividades.map((atividade) => (
                    <div key={atividade.id}>
                      {atividadeEditandoId === atividade.id ? (
                        <S.AtividadesCard>
                          <input
                            className="form-control mb-3"
                            type="text"
                            name="titulo_ativ"
                            value={formAtividades.titulo_ativ}
                            onChange={handleInputChangeAtividade}
                          />
                          <textarea
                            className="form-control mb-3"
                            name="desc_ativ"
                            value={formAtividades.desc_ativ}
                            onChange={handleTextAreaChangeAtividade}
                          />
                          <div>
                            <button
                              className="btn btn-secondary w-100 mb-2"
                              type="button"
                              onClick={() => setAtividadeEditandoId(null)}
                            >
                              Cancelar
                            </button>
                            <button
                              className="btn btn-success w-100"
                              type="button"
                              onClick={() => {
                                handlePutAtividade(atividade.id)
                                alert('Atividade alterada com sucesso')
                              }}
                            >
                              Salvar
                            </button>
                          </div>
                        </S.AtividadesCard>
                      ) : (
                        <S.AtividadesCard>
                          <S.TitleContainer>
                            <div>
                              <h4>{atividade.titulo_ativ}</h4>
                            </div>
                            <div>
                              <S.DeleteBtn
                                type="button"
                                onClick={() => deleteAtividade(atividade.id)}
                              >
                                <BsFillTrash3Fill />
                              </S.DeleteBtn>
                            </div>
                          </S.TitleContainer>
                          <p>{atividade.desc_ativ}</p>
                          <button
                            type="button"
                            className="btn btn-warning w-100 mt-3"
                            onClick={() => {
                              setAtividadeEditandoId(atividade.id)
                              seedFormAtividades(atividade)
                            }}
                          >
                            Editar
                          </button>
                        </S.AtividadesCard>
                      )}
                    </div>
                  ))}
                </form>
              </div>

              <div className={isEditing ? 'hidden' : ''}>
                <div className="mb-3">
                  <S.LabelContainer>
                    <Txt.TitleMd>Objetivo principal</Txt.TitleMd>
                  </S.LabelContainer>
                  <Txt.TextMd className="text-center">
                    {turma.objetivo}
                  </Txt.TextMd>
                </div>
                <div className="mb-3">
                  <S.LabelContainer>
                    <Txt.TitleMd>O que será desenvolvido</Txt.TitleMd>
                  </S.LabelContainer>
                  <Txt.TextMd className="text-center">
                    {turma.desenvolver}
                  </Txt.TextMd>
                </div>
                <div className="mb-3">
                  <S.LabelContainer>
                    <Txt.TitleMd>Atividades típicas</Txt.TitleMd>
                  </S.LabelContainer>

                  {turma.atividades.map((atividade) => (
                    <S.AtividadesCard key={atividade.id}>
                      <Txt.TitleMd>{atividade.titulo_ativ}</Txt.TitleMd>
                      <Txt.TextMd>{atividade.desc_ativ}</Txt.TextMd>
                    </S.AtividadesCard>
                  ))}
                </div>
              </div>
              <div className={isEditing ? 'mb-3' : 'hidden'}>
                <AdminTurmaAtivForm turmaId={turma.id} />
              </div>
              <button
                className={isEditing ? 'hidden' : 'btn btn-warning w-100'}
                onClick={() => {
                  toggleEditing(turma.id)
                  seedFormTurma(turma)
                }}
              >
                Editar
              </button>
              <button
                className={isEditing ? 'btn btn-success w-100' : 'hidden'}
                onClick={() => {
                  toggleEditing(turma.id)
                  handlePutTurma(turma.id)
                }}
              >
                Salvar mudanças
              </button>
            </S.TurmaCardBody>
          </S.TurmaCard>
        )
      })}
    </>
  )
}

export default AdminTurmaCard
