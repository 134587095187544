import { useState } from 'react'
import { BsFillTrash3Fill } from 'react-icons/bs'

import { Vagas } from '../../containers/AdminTrabalheList'
import * as S from './styles'

interface TrabalheProps {
  id: string
  nome_vaga: string
  desc_vaga: string
  req_vaga: string
  salario: string
  isEditing: boolean
  setIsEditing: (value: boolean) => void
}

const AdminTrabalheCardEdit = ({
  id,
  nome_vaga,
  desc_vaga,
  req_vaga,
  salario,
  isEditing,
  setIsEditing
}: TrabalheProps) => {
  const [vagasError, setVagasError] = useState<string | null>(null)
  const [vagas, setVagas] = useState<Vagas[]>([])
  const [error, setError] = useState<string | null>(null)
  const [editingVagaId, setEditingVagaId] = useState<string | null>(null)
  const [formValues, setFormValues] = useState({
    id: id,
    nome_vaga: nome_vaga,
    desc_vaga: desc_vaga,
    req_vaga: req_vaga,
    salario: salario,
    disponivel: false
  })

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target
    setFormValues((prev) => ({ ...prev, [name]: value }))
  }

  const handleSave = async (id: string) => {
    try {
      const response = await fetch(`http://localhost:8000/api/vagas/${id}/`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formValues)
      })

      if (!response.ok) {
        throw new Error('Falha ao salvar as alterações.')
      }

      const updatedVaga = await response.json()

      setVagas((prevVagas) =>
        prevVagas.map((vaga) => (vaga.id === id ? updatedVaga : vaga))
      )
      alert('Alterações salvas com sucesso!')
      setIsEditing(false)
      window.location.reload()
    } catch (err: any) {
      setError(err.message)
    }
  }

  const deleteVaga = async (id_vaga: string) => {
    try {
      const response = await fetch(
        `http://localhost:8000/api/vagas/${id_vaga}/`,
        {
          method: 'DELETE'
        }
      )
      if (!response.ok) {
        throw new Error(
          `Erro ao deletar o aviso da API em localhost:8000/api/avisos/${id_vaga}`
        )
      }
      alert('Vaga deletada com sucesso!')
      window.location.reload()
    } catch (err: any) {
      setError(err.message)
    }
  }

  return (
    <>
      <S.AdminVagaCard>
        <div className="d-flex justify-content-between align-items-center mb-3">
          <div className="form-floating mb-3 w-75">
            <input
              className="form-control"
              placeholder="Descrição"
              id="nome_vaga"
              name="nome_vaga"
              value={formValues.nome_vaga}
              onChange={handleInputChange}
            />
            <label htmlFor="nome_vaga">Título da vaga</label>
          </div>
          <div className="pt-0 pb-3 ps-3 pe-3">
            <S.DeleteBtn type="button" onClick={() => deleteVaga(id)}>
              <BsFillTrash3Fill size={25} />
            </S.DeleteBtn>
          </div>
        </div>
        <div className="form-floating mb-3">
          <textarea
            className="form-control"
            placeholder="Descrição"
            id="descricao"
            name="desc_vaga"
            style={{ height: '100px' }}
            value={formValues.desc_vaga}
            onChange={handleInputChange}
          />
          <label htmlFor="descricao">Descrição</label>
        </div>
        <div className="form-floating mb-3">
          <textarea
            className="form-control"
            placeholder="Requisitos"
            id="requisitos"
            name="req_vaga"
            style={{ height: '100px' }}
            value={formValues.req_vaga}
            onChange={handleInputChange}
          />
          <label htmlFor="requisitos">Requisitos</label>
        </div>
        <div className="form-floating mb-3">
          <input
            className="form-control"
            placeholder="Salario"
            id="salario"
            name="salario"
            value={formValues.salario}
            onChange={handleInputChange}
          />
          <label htmlFor="salario">Salário</label>
        </div>
        <div>
          <button
            type="button"
            className="btn btn-secondary w-100 mt-2"
            onClick={() => setIsEditing(false)}
          >
            Cancelar edição
          </button>
          <button
            type="button"
            className="btn btn-success w-100 mt-2"
            onClick={() => handleSave(id)}
          >
            Salvar alterações
          </button>
        </div>
      </S.AdminVagaCard>
    </>
  )
}

export default AdminTrabalheCardEdit
