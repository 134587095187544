import { Swiper, SwiperSlide } from 'swiper/react'
import { EffectCreative } from 'swiper/modules'

import TurmaCard from '../TurmaCard'

import * as S from './styles'
import { Turmas } from '../../containers/Turmas'

interface Props {
  turma: Turmas[] | null
}

const TurmasPreEscolar = ({ turma }: Props) => {
  return (
    <>
      <Swiper
        className="mySwiper"
        grabCursor={true}
        effect={'creative'}
        modules={[EffectCreative]}
        creativeEffect={{
          prev: {
            shadow: true,
            translate: [0, 0, -300]
          },
          next: {
            translate: ['100%', 0, 0]
          }
        }}
      >
        {turma?.map((t) => (
          <SwiperSlide key={t.id}>
            <TurmaCard turmas={t} atividades={t.atividades} />
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  )
}

export default TurmasPreEscolar
