import { useEffect, useState } from 'react'
import AdminTrabalheCard from '../../components/AdminTrabalheCard'
import AdminTrabalheCreateForm from '../../components/AdminTrabalheCreateForm'

export interface Vagas {
  id: string
  nome_vaga: string
  desc_vaga: string
  req_vaga: string
  salario: string
  created_at: string
  disponivel: boolean
}

const AdminTrabalheList = () => {
  const [vagasError, setVagasError] = useState<string | null>(null)
  const [vagas, setVagas] = useState<Vagas[]>([])
  const [error, setError] = useState<string | null>(null)

  const fetchVagas = async () => {
    try {
      const response = await fetch('http://localhost:8000/api/vagas/')
      if (!response.ok) {
        throw new Error(
          'Não foi possível requisitar os dados de localhost:8000/api/vagas/'
        )
      }
      const data = await response.json()
      setVagas(data)
    } catch (err: any) {
      setVagasError(err.message)
    }
  }

  useEffect(() => {
    fetchVagas()
  }, [])

  return (
    <>
      {vagas.map((vaga) => (
        <AdminTrabalheCard
          key={vaga.id}
          id={vaga.id}
          nome_vaga={vaga.nome_vaga}
          desc_vaga={vaga.desc_vaga}
          req_vaga={vaga.req_vaga}
          salario={vaga.salario}
        />
      ))}
      <div>
        <AdminTrabalheCreateForm />
      </div>
    </>
  )
}

export default AdminTrabalheList
