import { useEffect, useState, useMemo } from 'react'
import {
  MaterialReactTable,
  useMaterialReactTable,
  type MRT_ColumnDef
} from 'material-react-table'
import { BsDownload } from 'react-icons/bs'
import { unparse } from 'papaparse'

type Candidato = {
  vaga_escolhida: string
  nome: string
  idade: string
  sexo: string
  endereco: string
  tel: string
  email: string
  cv: string
}

const AdminCandidatosTable = () => {
  const [candidatos, setCandidatos] = useState<Candidato[]>([])
  const [candidatosError, setCandidatosError] = useState<string | null>(null)

  const fetchCandidatos = async () => {
    try {
      const response = await fetch('http://localhost:8000/api/candidatos/')
      if (!response.ok) {
        throw new Error(
          'Não foi possível requisitar os dados de localhost:8000/api/candidatos/'
        )
      }
      const data = await response.json()
      setCandidatos(data)
    } catch (err: any) {
      setCandidatosError(err.message)
    }
  }

  useEffect(() => {
    fetchCandidatos()
  }, [])

  const columns = useMemo<MRT_ColumnDef<Candidato>[]>(
    () => [
      {
        accessorKey: 'nome',
        header: 'Nome Completo'
      },
      {
        accessorKey: 'idade',
        header: 'Idade'
      },
      {
        accessorKey: 'sexo',
        header: 'Sexo'
      },
      {
        accessorKey: 'endereco',
        header: 'Endereço'
      },
      {
        accessorKey: 'tel',
        header: 'Telefone'
      },
      {
        accessorKey: 'email',
        header: 'E-mail'
      },
      {
        accessorKey: 'vaga_escolhida',
        header: 'Vaga desejada'
      },
      {
        accessorKey: 'cv',
        header: 'Currículo',
        Cell: ({ cell }) => (
          <a
            href={cell.getValue<string>()}
            className="btn btn-success text-small"
          >
            <BsDownload /> Download
          </a>
        ),
        enableSorting: false,
        enableColumnFilter: false
      }
    ],
    []
  )

  const handleExportData = () => {
    const selectedRowIds = Object.keys(tabela.getState().rowSelection).map(
      Number
    )
    return selectedRowIds.length > 0
      ? candidatos.filter((_, index) => selectedRowIds.includes(index))
      : candidatos
  }

  const exportCSV = (data: Candidato[], filename: 'dados.csv') => {
    const csv = unparse(data)

    const blob = new Blob([csv], { type: 'text/csv;charset=utf8' })
    const link = document.createElement('a')
    const url = URL.createObjectURL(blob)

    link.setAttribute('href', url)
    link.setAttribute('download', filename)

    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  const data = useMemo(() => candidatos, [candidatos])

  const tabela = useMaterialReactTable({
    columns,
    data,
    enableRowSelection: true,
    renderTopToolbarCustomActions: ({ table }) => (
      <button
        className="btn btn-success"
        onClick={() => exportCSV(handleExportData(), 'dados.csv')}
      >
        <BsDownload /> Baixar CSV
      </button>
    )
  })

  return <MaterialReactTable table={tabela} />
}

export default AdminCandidatosTable
