import styled from 'styled-components'

import { cores } from '../../styles'

export const CardTurma = styled.div`
  background-color: ${cores.corPrincipal};
  color: #fff;
  padding: 10px;
  border-radius: 16px;
  margin: 20px auto;

  .img_placeholder {
    background-color: ${cores.corPrincipal};
    width: 100%;
    border-radius: 8px;
    margin-bottom: 24px;
  }
`

export const CardTurmaStroke = styled.div`
  border: 6px solid ${cores.corSecundaria};
  border-radius: 10px;
  padding: 16px;
`
