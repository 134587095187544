import { useEffect, useState } from 'react'
import { BsArrowRight } from 'react-icons/bs'

import 'swiper/css'
import 'swiper/css/effect-cards'

import * as S from './styles'
import * as Global from '../../styles/index'
import * as Txt from '../../styles/text'

import TurmasPreEscolar from '../../components/TurmasPreEscolar'
import TurmasFundamental from '../../components/TurmasFundamental'

export interface Turmas {
  id: string
  atividades: Atividades[]
  nome_turma: string
  nome_formal: string
  idade_turma: string
  objetivo: string
  desenvolver: string
  posicao: number
  imagem: string
  categoria: string
}

export interface Atividades {
  id: string
  titulo_ativ: string
  desc_ativ: string
  posicao: number
  turma: string
}

const Turmas = () => {
  const [turmas, setTurmas] = useState<Turmas[] | null>(null)
  const [turmasError, setTurmasError] = useState<string | null>(null)

  const fetchTurmas = async () => {
    try {
      const response = await fetch('http://localhost:8000/api/turmas/')
      if (!response.ok) {
        throw new Error(
          'Não foi possível requisitar os dados de localhost:8000/api/turmas/'
        )
      }
      const data = await response.json()
      setTurmas(data)
    } catch (err: any) {
      setTurmasError(err.message)
    }
  }

  const turmasPreEscolar =
    turmas?.filter((t) => t.categoria === 'pre_escolar') || []
  const turmasFundamental =
    turmas?.filter((t) => t.categoria === 'fundamental') || []

  useEffect(() => {
    fetchTurmas()
  }, [])

  return (
    <>
      {/* <ModalTurmas /> */}
      <S.QuadroTurmas id="turmas">
        <S.TabNavbar className="nav nav-tabs" id="myTab" role="tablist">
          <li className="nav-item" role="presentation">
            <S.TabSelector
              className="nav-link active"
              id="home-tab"
              data-bs-toggle="tab"
              data-bs-target="#home-tab-pane"
              type="button"
              role="tab"
              aria-controls="home-tab-pane"
              aria-selected="true"
            >
              <h6>Pré-escolar</h6>
            </S.TabSelector>
          </li>
          <li className="nav-item" role="presentation">
            <S.TabSelector
              className="nav-link"
              id="profile-tab"
              data-bs-toggle="tab"
              data-bs-target="#profile-tab-pane"
              type="button"
              role="tab"
              aria-controls="profile-tab-pane"
              aria-selected="false"
            >
              <h6>Ensino Fundamental</h6>
            </S.TabSelector>
          </li>
        </S.TabNavbar>
        <S.TabBody className="tab-content" id="myTabContent">
          <div
            className="tab-pane fade show active"
            id="home-tab-pane"
            role="tabpanel"
            aria-labelledby="home-tab"
            tabIndex={0}
          >
            <Global.Container>
              <Txt.TextMd className="mt-3 mb-0 text-white">
                Arraste para o lado
                <BsArrowRight className="text-white fs-5 ms-2" />
              </Txt.TextMd>
              {turmasPreEscolar.length === 0 && (
                <p>Não há turmas pré-escolares disponíveis.</p>
              )}
              <TurmasPreEscolar turma={turmasPreEscolar} />
            </Global.Container>
          </div>
          <div
            className="tab-pane fade"
            id="profile-tab-pane"
            role="tabpanel"
            aria-labelledby="profile-tab"
            tabIndex={1}
          >
            <Global.Container>
              <Txt.TextMd className="mt-3 mb-0 text-white">
                Arraste para o lado
                <BsArrowRight className="text-white fs-5 ms-2" />
              </Txt.TextMd>
              <TurmasFundamental turma={turmasFundamental} />
            </Global.Container>
          </div>
        </S.TabBody>
        <S.LineDivision className="big" />
        <S.LineDivision />
        <S.LineDivision className="small" />
      </S.QuadroTurmas>
    </>
  )
}

export default Turmas
