import * as S from './styles'
import * as Txt from '../../styles/text'

import { Atividades, Turmas } from '../../containers/Turmas'
import { Link } from 'react-router-dom'

interface Props {
  turmas: Turmas
  atividades: Atividades[]
}

const TurmaCard = ({ turmas, atividades }: Props) => {
  return (
    <>
      <S.CardTurma key={turmas.id}>
        <S.CardTurmaStroke>
          <img
            src={turmas.imagem}
            alt={`Imagem da turma ${turmas.nome_turma}`}
            title={`Imagem da turma ${turmas.nome_turma}`}
            className="img_placeholder"
          />
          <Txt.TitleBig>{turmas.nome_turma}</Txt.TitleBig>
          <Txt.TitleSm>{turmas.nome_formal}</Txt.TitleSm>
          <Txt.TextMd className="mt-3">{turmas.idade_turma}</Txt.TextMd>
          <Link to={`/turma/${turmas.id}`} className="btn btn-warning w-100">
            Veja mais sobre
          </Link>
        </S.CardTurmaStroke>
      </S.CardTurma>
    </>
  )
}

export default TurmaCard
