import { useEffect, useState } from 'react'

import CardVaga from '../../components/CardVaga'

import * as Global from '../../styles/index'
import * as S from './styles'

interface Vagas {
  id: string
  nome_vaga: string
  desc_vaga: string
  req_vaga: string
  salario: string
  created_at: string
  disponivel: boolean
}

const Vagas = () => {
  const [vagasError, setVagasError] = useState<string | null>(null)
  const [vagas, setVagas] = useState<Vagas[]>([])

  const fetchVagas = async () => {
    try {
      const response = await fetch('http://localhost:8000/api/vagas/')
      if (!response.ok) {
        throw new Error(
          'Não foi possível requisitar os dados de localhost:8000/api/vagas/'
        )
      }
      const data = await response.json()
      setVagas(data)
      console.log(data)
    } catch (err: any) {
      setVagasError(err.message)
    }
  }

  useEffect(() => {
    fetchVagas()
  }, [])

  return (
    <S.VagasContainer>
      <Global.Container>
        {vagas.map((vaga) => (
          <div key={vaga.id}>
            <CardVaga
              id={vaga.id}
              nome_vaga={vaga.nome_vaga}
              desc_vaga={vaga.desc_vaga}
              req_vaga={vaga.req_vaga}
              salario={vaga.salario}
              created_at={vaga.created_at}
              disponivel={vaga.disponivel}
            />
          </div>
        ))}
      </Global.Container>
    </S.VagasContainer>
  )
}

export default Vagas
