import AdminCandidatosTable from '../../components/AdminCandidatosTable'

const AdminCandidatosList = () => {
  return (
    <>
      <div className="mt-3">
        <h3 className="text-center text-white">Importante:</h3>
        <p className="text-center text-white fw-light">
          Ao clicar em &quot;Baixar CSV&quot; todo o conteúdo da tabela será
          exportado para uma planilha no formato &quot;.csv&quot;.
        </p>
        <p className="text-center text-white fw-light mb-4">
          Se deseja exportar linhas específicas, por favor, utilize os filtros
          da tabela e marque as linhas que deseja exportar.
        </p>
        <AdminCandidatosTable />
      </div>
    </>
  )
}

export default AdminCandidatosList
