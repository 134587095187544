import { useEffect, useState } from 'react'

import * as S from './styles'
import * as Txt from '../../styles/text'

interface Sobre {
  id: string
  sobre_escola: string
  missao: string
  visao: string
  valores: string
}

const Sobre = () => {
  const [sobre, setSobre] = useState<Sobre | null>(null)
  const [sobreError, setSobreError] = useState<string | null>(null)

  const fetchSobre = async () => {
    try {
      const response = await fetch('http://localhost:8000/api/sobre/')
      if (!response.ok) {
        throw new Error(
          'Não foi possível requisitar os dados de localhost:8000/api/sobre/'
        )
      }
      const data = await response.json()
      setSobre(data[0])
    } catch (err: any) {
      setSobreError(err.message)
    }
  }

  useEffect(() => {
    fetchSobre()
  }, [])

  if (sobreError) {
    return <p>Erro ao carregar dados: {sobreError}</p>
  }

  if (!sobre) {
    return <p>Carregando...</p>
  }

  return (
    <>
      <S.QuadroSobre id="sobre">
        <Txt.TitleBig>Sobre nós</Txt.TitleBig>
        <img
          src="https://static-file-vault.vercel.app/cdtchechela/logo_cdtcc.svg"
          alt=""
          width="50%"
        />
        <Txt.TextMd>{sobre.sobre_escola}</Txt.TextMd>
        <S.BodyContent>
          <img
            src="https://imgs.search.brave.com/tTxzGGn9iXSULwNO4SN0ErzjEIn_Z0Y8y01mt5JUyyw/rs:fit:860:0:0:0/g:ce/aHR0cHM6Ly9ibG9n/LXZvb21wLnMzLnVz/LWVhc3QtMS5hbWF6/b25hd3MuY29tL3dw/LWNvbnRlbnQvdXBs/b2Fkcy8yMDIxLzA2/L3ByZWNpc29fZmF6/ZXJfZmFjdWxkYWRl/X3BhcmFfc2VyX3By/b2Zlc3Nvcl9kb19l/bnNpbm9fZnVuZGFt/ZW50YWwuanBn"
            alt=""
            width="100%"
            className="mb-3 rounded-3"
          />
        </S.BodyContent>
        <S.AccordionContainer className="accordion" id="accordionAvisos">
          <div className="accordion-item">
            <h2 className="accordion-header">
              <S.AccordionBtn
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne"
              >
                Missão
              </S.AccordionBtn>
            </h2>
            <div
              id="collapseOne"
              className="accordion-collapse collapse"
              data-bs-parent="#accordionAvisos"
            >
              <div className="accordion-body">{sobre.missao}</div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header">
              <S.AccordionBtn
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTwo"
                aria-expanded="false"
                aria-controls="collapseTwo"
              >
                Visão
              </S.AccordionBtn>
            </h2>
            <div
              id="collapseTwo"
              className="accordion-collapse collapse"
              data-bs-parent="#accordionAvisos"
            >
              <div className="accordion-body">{sobre.visao}</div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header">
              <S.AccordionBtn
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseThree"
                aria-expanded="false"
                aria-controls="collapseThree"
              >
                Valores
              </S.AccordionBtn>
            </h2>
            <div
              id="collapseThree"
              className="accordion-collapse collapse"
              data-bs-parent="#accordionAvisos"
            >
              <div className="accordion-body">{sobre.valores}</div>
            </div>
          </div>
        </S.AccordionContainer>
      </S.QuadroSobre>
    </>
  )
}

export default Sobre
