import ModalVaga from '../ModalVaga'

interface cardProps {
  id: string
  nome_vaga: string
  desc_vaga: string
  req_vaga: string
  salario: string
  created_at: string
  disponivel: boolean
}

const CardVaga = ({
  id,
  nome_vaga,
  desc_vaga,
  req_vaga,
  salario,
  created_at,
  disponivel
}: cardProps) => {
  return (
    <div className="card mb-3">
      <div className="card-body">
        <h5 className="card-title">{nome_vaga}</h5>
        <p className="card-text"></p>
        <button
          className="btn btn-warning"
          data-bs-toggle="modal"
          data-bs-target={`#staticBackdrop-${nome_vaga.replace(/\s/g, '')}`}
        >
          Ver detalhes da vaga
        </button>
        <ModalVaga
          id={id}
          nome_vaga={nome_vaga}
          desc_vaga={desc_vaga}
          req_vaga={req_vaga}
          salario={salario}
          created_at={created_at}
          disponivel={disponivel}
        />
      </div>
    </div>
  )
}

export default CardVaga
