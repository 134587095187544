import AdminMenu from '../../components/AdminMenu'
import AdminTopMenu from '../../components/AdminTopMenu'

import * as S from './styles'
import AdminAvisosList from '../../components/AdminAvisosList'
import AdminAvisosForm from '../../components/AdminAvisosForm'

const AdminAvisos = () => {
  return (
    <>
      <S.bgAdmin>
        <AdminTopMenu title="Avisos" />
        <AdminMenu />
        <AdminAvisosList />
        <AdminAvisosForm />
      </S.bgAdmin>
    </>
  )
}

export default AdminAvisos
