import { useState } from 'react'
import * as S from './styles'

interface NovaAtividade {
  titulo_ativ: string
  desc_ativ: string
  posicao: number
  turma: string
}

interface AdminTurmaAtivFormProps {
  turmaId: string
}

const AdminTurmaAtivForm = ({ turmaId }: AdminTurmaAtivFormProps) => {
  const [atividadeIsActive, setAtividadeIsActive] = useState<boolean>(false)
  const [novaAtividade, setNovaAtividade] = useState<NovaAtividade>({
    titulo_ativ: '',
    desc_ativ: '',
    posicao: 0,
    turma: turmaId // Agora a turma é definida pela prop
  })
  const [erro, setErro] = useState<string | null>(null)

  const toggleActive = () => setAtividadeIsActive((prev) => !prev)

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target
    setNovaAtividade((prev) => ({ ...prev, [name]: value }))
  }

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault()

    try {
      const response = await fetch('http://localhost:8000/api/atividades/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(novaAtividade)
      })

      if (!response.ok) {
        throw new Error('Erro ao salvar a atividade.')
      }

      alert('Atividade criada com sucesso!')
      setNovaAtividade({
        titulo_ativ: '',
        desc_ativ: '',
        posicao: 0,
        turma: turmaId
      }) // Limpa os campos, mas mantém o id da turma
      setAtividadeIsActive(false) // Fecha o formulário
      window.location.reload()
    } catch (error: any) {
      setErro(error.message)
    }
  }

  return (
    <>
      <S.HiddenContainer>
        <S.FormTurma
          action=""
          className={atividadeIsActive ? '' : 'hidden'}
          onSubmit={handleSubmit}
        >
          <div className="form-floating mb-3">
            <S.InputTitle
              type="text"
              className="form-control"
              id="tituloAtividade"
              name="titulo_ativ"
              placeholder="Título da atividade"
              value={novaAtividade.titulo_ativ}
              onChange={handleChange}
            />
            <S.FormLabel htmlFor="tituloAtividade">
              Título da atividade
            </S.FormLabel>
          </div>

          <div className="form-floating">
            <textarea
              className="form-control"
              id="descAtividade"
              name="desc_ativ"
              placeholder="Descrição da atividade"
              value={novaAtividade.desc_ativ}
              onChange={handleChange}
            />
            <S.FormLabel htmlFor="descAtividade">Descrição</S.FormLabel>
          </div>

          {erro && <p style={{ color: 'red' }}>{erro}</p>}

          <button
            type="button"
            className="btn btn-secondary w-100 mt-3"
            onClick={toggleActive}
          >
            Cancelar
          </button>
          <button type="submit" className="btn btn-success w-100 mt-2">
            Salvar atividade
          </button>
        </S.FormTurma>
      </S.HiddenContainer>

      <S.HiddenContainer>
        <button
          type="button"
          className={
            atividadeIsActive ? 'hidden' : 'btn btn-warning w-100 mt-3'
          }
          onClick={toggleActive}
        >
          Adicionar Atividade
        </button>
      </S.HiddenContainer>
    </>
  )
}

export default AdminTurmaAtivForm
