import styled from 'styled-components'
import { cores } from '../../styles'

export const CardDetalhes = styled.div`
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.6);
`

export const DetalhesTitle = styled.h1`
  color: ${cores.corPrincipal};
  font-weight: bold;
  text-align: center;
`
