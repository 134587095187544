import { useState } from 'react'

const AdminTrabalheCreateForm = () => {
  const [vaga, setVaga] = useState({
    id: '',
    nome_vaga: '',
    desc_vaga: '',
    req_vaga: '',
    salario: '',
    disponivel: true
  })

  const limpaCampos = () => {
    setVaga({
      id: '',
      nome_vaga: '',
      desc_vaga: '',
      req_vaga: '',
      salario: '',
      disponivel: true
    })
  }

  const handleSave = async () => {
    try {
      const response = await fetch('http://localhost:8000/api/vagas/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(vaga)
      })
      alert('Vaga cadastrada com sucesso!')
      window.location.reload()
    } catch (error) {
      console.error('Erro ao salvar vaga:', error)
    }
  }

  return (
    <>
      <div
        className="modal fade"
        id="modalVaga"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="modalVagaLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <form action="">
              <div className="modal-header">
                <div className="form-floating mb-3">
                  <input
                    type="text"
                    className="form-control"
                    id="tituloVaga"
                    name="titulo_ativ"
                    placeholder="Título da vaga"
                    style={{ width: '330px' }}
                    value={vaga.nome_vaga}
                    onChange={(e) =>
                      setVaga({ ...vaga, nome_vaga: e.target.value })
                    }
                  />
                  <label htmlFor="tituloVaga">Título da vaga</label>
                </div>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => limpaCampos()}
                ></button>
              </div>
              <div className="modal-body">
                <div className="form-floating mb-3">
                  <textarea
                    className="form-control"
                    placeholder="Descrição da vaga"
                    id="descricaoVaga"
                    name="descricao_vaga"
                    style={{ height: '160px' }}
                    value={vaga.desc_vaga}
                    onChange={(e) =>
                      setVaga({ ...vaga, desc_vaga: e.target.value })
                    }
                  ></textarea>
                  <label htmlFor="descricaoVaga">Descrição da vaga</label>
                </div>
                <div className="form-floating mb-3">
                  <textarea
                    className="form-control"
                    placeholder="Requisitos para a vaga"
                    id="requisitosVaga"
                    name="requisitos_vaga"
                    style={{ height: '160px' }}
                    value={vaga.req_vaga}
                    onChange={(e) =>
                      setVaga({ ...vaga, req_vaga: e.target.value })
                    }
                  ></textarea>
                  <label htmlFor="descricaoVaga">Requisitos para a vaga</label>
                </div>
                <div className="form-floating mb-3">
                  <input
                    className="form-control"
                    placeholder="Salário"
                    id="salarioVaga"
                    name="salario_vaga"
                    value={vaga.salario}
                    onChange={(e) =>
                      setVaga({ ...vaga, salario: e.target.value })
                    }
                  />
                  <label htmlFor="descricaoVaga">Salário</label>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                  onClick={() => limpaCampos()}
                >
                  Cancelar
                </button>
                <button
                  type="button"
                  className="btn btn-success"
                  onClick={handleSave}
                >
                  Salvar
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <button
        type="button"
        className="btn btn-success w-100 mt-3 mb-5"
        data-bs-toggle="modal"
        data-bs-target="#modalVaga"
      >
        Adicionar vaga
      </button>
    </>
  )
}

export default AdminTrabalheCreateForm
