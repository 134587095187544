import styled from 'styled-components'

export const AdminVagaCard = styled.div`
  background-color: #fff;
  margin-top: 20px;
  border-radius: 8px;
  padding: 24px;

  h2 {
    margin-bottom: 20px;
  }
`

export const EditBtn = styled.button`
  margin-top: 16px;
`

export const DeleteBtn = styled.button`
  background-color: rgba(255, 0, 0, 0.6);
  color: #fff;
  padding: 8px 8px;
  border-radius: 50%;
  border: none;
  filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.3));
  transition: ease 0.3s;

  &:hover {
    background-color: rgba(255, 0, 0, 0.8);
    transition: ease 0.3s;
  }
`
