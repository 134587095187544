import AdminMenu from '../../components/AdminMenu'
import AdminTopMenu from '../../components/AdminTopMenu'
import AdminCandidatosList from '../../containers/AdminCandidatosList'
import * as BG from '../AdminArea/styles'

const AdminCandidatos = () => {
  return (
    <>
      <BG.bgAdmin>
        <AdminTopMenu title="Listagem de candidatos" />
        <AdminMenu />
        <AdminCandidatosList />
      </BG.bgAdmin>
    </>
  )
}

export default AdminCandidatos
