import { useState } from 'react'

import * as S from './styles'
import * as Txt from '../../styles/text'

interface formProps {
  nome_vaga: string
}

interface Candidato {
  vaga_escolhida: string
  nome: string
  idade: string
  sexo: string
  endereco: string
  tel: string
  email: string
  cv: File | null
}

const FormVaga = ({ nome_vaga }: formProps) => {
  const [vaga, setVaga] = useState(nome_vaga)
  const [candidato, setCandidato] = useState<Candidato>({
    vaga_escolhida: nome_vaga,
    nome: '',
    idade: '',
    sexo: '',
    endereco: '',
    tel: '',
    email: '',
    cv: null
  })

  const [file, setFile] = useState<File | null>(null)
  const [uploadError, setUploadError] = useState<string | null>(null)

  const handleSave = async () => {
    if (!candidato.cv) {
      alert(
        'Por favor, anexe seu currículo antes de enviar. Formatos permitidos: .pdf, .doc, .docx'
      )
      return
    }

    const formData = new FormData()
    formData.append('cv', candidato.cv) // 'selectedFile' é o arquivo escolhido pelo usuário
    formData.append('nome', candidato.nome)
    formData.append('email', candidato.email)
    formData.append('vaga_escolhida', candidato.vaga_escolhida)
    formData.append('idade', candidato.idade)
    formData.append('sexo', candidato.sexo)
    formData.append('tel', candidato.tel)
    formData.append('endereco', candidato.endereco)
    formData.forEach((value, key) => {
      console.log(key + ': ' + value)
    })
    // Object.entries(candidato).forEach(([key, value]) => {
    //   if (value) formData.append(key, value as string | Blob)
    // })

    try {
      const response = await fetch('http://localhost:8000/api/candidatos/', {
        method: 'POST',
        body: formData
      })
      if (!response.ok) {
        throw new Error(
          'Erro ao fazer o upload do curriculo em localhost:8000/api/candidatos/'
        )
      }
      alert('Currículo enviado com sucesso!')
      setCandidato({
        vaga_escolhida: nome_vaga,
        nome: '',
        idade: '',
        sexo: '',
        endereco: '',
        tel: '',
        email: '',
        cv: null
      })
    } catch (err: any) {
      setUploadError(err.message)
    }
  }

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0]
    if (selectedFile) {
      if (selectedFile.size > 8 * 1024 * 1024) {
        setUploadError(
          'O arquivo é muito grande. Tamanho máximo permitido: 8MB.'
        )
        alert(uploadError)
        return
      }

      const allowedTypes = [
        'application/pdf',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
      ]
      if (!allowedTypes.includes(selectedFile.type)) {
        setUploadError('Formato inválido. Apenas PDF e DOCX são permitidos.')
        alert('Formato inválido. Apenas PDF e DOCX são permitidos.')
        return
      }

      // Se passou na validação
      setCandidato((prev) => ({ ...prev, cv: selectedFile }))
      setUploadError(null)
    }
  }

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target
    setCandidato((prev) => ({ ...prev, [name]: value }))
  }

  return (
    <>
      <form action="">
        <S.selectInput className="form-floating mb-3 big">
          <select
            className="form-select"
            aria-label="select_sexo"
            disabled
            value={nome_vaga}
            name="vaga_escolhida"
          >
            <option selected>{`Vaga desejada: ${nome_vaga}`}</option>
          </select>
        </S.selectInput>
        <div className="form-floating mb-3">
          <input
            type="text"
            className="form-control"
            id="nomeInput"
            placeholder="Nome"
            value={candidato.nome}
            name="nome"
            onChange={handleInputChange}
          />
          <label htmlFor="nomeInput">Nome Completo</label>
        </div>
        <S.doubleInputContainer>
          <S.idadeInput className="form-floating mb-3">
            <input
              type="text"
              className="form-control"
              id="idadeInput"
              placeholder="Idade"
              value={candidato.idade}
              name="idade"
              onChange={handleInputChange}
            />
            <label htmlFor="idadeInput">Idade</label>
          </S.idadeInput>
          <S.selectInput className="form-floating mb-3">
            <select
              className="form-select"
              aria-label="select_sexo"
              onChange={handleInputChange}
              value={candidato.sexo}
              name="sexo"
            >
              <option selected disabled>
                Sexo:
              </option>
              <option value="Masculino">Masculino</option>
              <option value="Feminino">Feminino</option>
            </select>
          </S.selectInput>
        </S.doubleInputContainer>
        <div className="form-floating mb-3">
          <input
            type="text"
            className="form-control"
            id="enderecoInput"
            placeholder="Endereço"
            onChange={handleInputChange}
            value={candidato.endereco}
            name="endereco"
          />
          <label htmlFor="enderecoInput">Endereço completo</label>
        </div>
        <div className="form-floating mb-3">
          <input
            type="tel"
            className="form-control"
            id="telInput"
            placeholder="Telefone"
            onChange={handleInputChange}
            value={candidato.tel}
            name="tel"
          />
          <label htmlFor="telInput">Telefone</label>
        </div>
        <div className="form-floating mb-3">
          <input
            type="email"
            className="form-control"
            id="emailInput"
            placeholder="E-mail"
            onChange={handleInputChange}
            value={candidato.email}
            name="email"
          />
          <label htmlFor="emailInput">E-mail</label>
        </div>
        <div>
          <label htmlFor="formFile" className="form-label">
            Por favor, anexe seu currículo abaixo:
          </label>
          <input
            className="form-control form-control-md"
            id="formFile"
            type="file"
            accept=".pdf,.doc,.docx"
            onChange={handleFileChange}
          />
        </div>
        <Txt.TextSm className="text-secondary mt-2">
          Formatos permitidos: .pdf, .doc, .docx
        </Txt.TextSm>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Cancelar
          </button>
          <button
            type="button"
            className="btn btn-success"
            data-bs-target={`#staticBackdrop${nome_vaga.replace(/\s/g, '')}-success`}
            data-bs-toggle="modal"
            onClick={handleSave}
          >
            Enviar
          </button>
        </div>
      </form>
    </>
  )
}

export default FormVaga
