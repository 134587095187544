import * as Txt from '../../styles/text'
import * as S from './styles'

import FormButtons from '../FormButtons'
import { useState, useEffect } from 'react'

interface Contato {
  id: string
  tel: string
  endereco: string
  endereco_link: string
  email: string
  facebook_link: string
  instagram_link: string
}

const AdminContatoCard = () => {
  const [contato, setContato] = useState<Contato | null>(null)
  const [contatoValues, setContatoValues] = useState({
    tel: '',
    endereco: '',
    endereco_link: '',
    email: '',
    facebook_link: '',
    instagram_link: ''
  })
  const [error, setError] = useState<string | null>(null)

  const fetchContato = async () => {
    try {
      const response = await fetch('http://localhost:8000/api/contato/')
      if (!response.ok) {
        throw new Error(
          'Não foi possível requisitar os dados de localhost:8000/api/contato/'
        )
      }
      const data = await response.json()
      const contatoData = Array.isArray(data) ? data[0] : data
      setContato(contatoData)
      setContatoValues({
        tel: contatoData.tel || '',
        endereco: contatoData.endereco || '',
        endereco_link: contatoData.endereco_link || '',
        email: contatoData.email || '',
        facebook_link: contatoData.facebook_link || '',
        instagram_link: contatoData.instagram_link || ''
      })
    } catch (err: any) {
      setError(err.message)
    }
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setContatoValues((prev) => ({ ...prev, [name]: value }))
  }

  const handleSave = async () => {
    if (!contato) return
    try {
      const response = await fetch(
        `http://localhost:8000/api/contato/${contato.id}/`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(contatoValues)
        }
      )

      if (!response.ok) {
        throw new Error('Erro ao salvar os dados')
      }

      const updatedSobre = await response.json()
      setContato(updatedSobre)
      alert('Alterações salvas com sucesso!')
    } catch (error: any) {
      console.error(error)
      alert('Erro ao salvar as alterações.')
    }
  }

  useEffect(() => {
    fetchContato()
  }, [])

  if (!contato) {
    return <p>Carregando info de contato</p>
  }

  return (
    <>
      <S.FormContatoCard>
        <Txt.TitleMd className="text-center">
          Alterar formas de contato da escola
        </Txt.TitleMd>
        <form action="">
          <div className="mb-3">
            <div className="form-floating mb-3">
              <input
                type="tel"
                className="form-control"
                id="tel"
                name="tel"
                placeholder="name@example.com"
                value={contatoValues.tel}
                onChange={handleInputChange}
              />
              <label htmlFor="tel">Telefone</label>
            </div>

            <div className="form-floating mb-3">
              <input
                type="text"
                className="form-control"
                id="endereco"
                name="endereco"
                placeholder="name@example.com"
                value={contatoValues.endereco}
                onChange={handleInputChange}
              />
              <label htmlFor="endereco">Endereço</label>
            </div>

            <div className="form-floating mb-3">
              <input
                type="email"
                className="form-control"
                id="email"
                name="email"
                placeholder="name@example.com"
                value={contatoValues.email}
                onChange={handleInputChange}
              />
              <label htmlFor="email">E-mail</label>
            </div>
          </div>

          <Txt.TitleMd className="text-center">
            Links de redes sociais da escola
          </Txt.TitleMd>

          <div className="form-floating mb-3">
            <input
              type="text"
              className="form-control"
              id="endereco_link"
              name="endereco_link"
              placeholder="name@example.com"
              value={contatoValues.endereco_link}
              onChange={handleInputChange}
            />
            <label htmlFor="endereco_link">Link do Google Maps</label>
          </div>

          <div className="form-floating mb-3">
            <input
              type="text"
              className="form-control"
              id="facebook_link"
              name="facebook_link"
              placeholder="name@example.com"
              value={contatoValues.facebook_link}
              onChange={handleInputChange}
            />
            <label htmlFor="facebook_link">Facebook</label>
          </div>

          <div className="form-floating mb-3">
            <input
              type="text"
              className="form-control"
              id="instagram_link"
              name="instagram_link"
              placeholder="name@example.com"
              value={contatoValues.instagram_link}
              onChange={handleInputChange}
            />
            <label htmlFor="instagram_link">Instagram</label>
          </div>

          <button className="btn btn-success w-100" onClick={handleSave}>
            Salvar mudanças
          </button>
        </form>
      </S.FormContatoCard>
    </>
  )
}

export default AdminContatoCard
