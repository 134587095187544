import styled from 'styled-components'
import { cores } from '../../styles'

export const TurmaCard = styled.div`
  background-color: #fff;
  margin-top: 20px;
  border-radius: 8px;
  padding: 24px;

  h2 {
    margin: 16px 0 24px;
  }

  .hidden {
    display: none;
  }
`

export const ImageContainer = styled.div`
  img {
    width: 100%;
    border-radius: 8px;
  }
`

export const TurmaCardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  h2 {
    margin: 0;
  }
`

export const TurmaCardBody = styled.div`
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.4s ease-in-out;
  margin-top: 8px;

  &.expanded {
    max-height: 3000px;
  }

  label {
    color: #000;
  }
`

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

export const ToggleButtonContainer = styled.div`
  cursor: pointer;

  svg {
    transition: ease 0.2s;
    background-color: ${cores.corPrincipal};
    color: #fff;
    padding: 8px;
    border-radius: 20px;

    &:hover {
      transform: scale(1.1);
      transition: ease 0.2s;
    }
  }
`

export const TurmaTextarea = styled.textarea`
  height: 150px;
`

export const LabelContainer = styled.div`
  display: flex;
  justify-content: space-around;
  margin-bottom: 8px;
`

export const AtividadesCard = styled.div`
  background-color: ${cores.corPrincipal};
  color: #fff;
  padding: 16px;
  border-radius: 8px;
  margin-bottom: 16px;

  h2 {
    margin: 0px 0px 8px;
    color: ${cores.corSecundaria};
  }

  p {
    margin: 0px;
  }
`

export const DeleteBtn = styled.button`
  background-color: rgba(255, 0, 0, 0.6);
  color: #fff;
  padding: 4px 8px;
  border-radius: 50%;
  border: none;
  filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.3));
  transition: ease 0.3s;

  &:hover {
    background-color: rgba(255, 0, 0, 0.8);
    transition: ease 0.3s;
  }
`
