import styled from 'styled-components'
import { cores } from '../../styles'

export const QuadroTurmas = styled.div`
  text-align: center;
  background-color: ${cores.corPrincipal};
`

export const CardTurma = styled.div`
  background-color: ${cores.corPrincipal};
  color: #fff;
  padding: 10px;
  border-radius: 16px;
  margin: 20px auto;

  .img_placeholder {
    width: 100%;
    border-radius: 8px;
    margin-bottom: 24px;
  }
`

export const CardTurmaStroke = styled.div`
  border: 6px solid ${cores.corSecundaria};
  border-radius: 10px;
  padding: 16px;
`

export const TabNavbar = styled.ul`
  background-color: ${cores.corPrincipal};
  border: none !important;
  justify-content: center;
`

export const TabSelector = styled.button`
  background-color: ${cores.softSecundaria};
  color: #fff;
  border: none !important;
  margin-right: 8px;

  &[aria-selected='true'] {
    background-color: ${cores.corSecundaria} !important;
    color: #fff !important;
    border: none;
  }

  &:hover {
    color: ${cores.corSecundaria};
  }

  &:focus {
    outline: none;
    box-shadow: none;
  }
`

export const TabBody = styled.div`
  background-color: ${cores.corSecundaria};
  padding: 8px;
  border-top: 1px solid ${cores.corPrincipal};
`

export const LineDivision = styled.div`
  height: 12px;
  background-color: ${cores.corSecundaria};
  margin-top: 16px;
  opacity: 0.7;

  &.big {
    height: 22px;
    opacity: 1;
  }

  &.small {
    height: 6px;
    opacity: 0.5;
  }
`
