import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { Turmas } from '../../containers/Turmas'
import Header from '../../containers/Header'
import Menu from '../../components/Menu'

import * as S from './styles'
import * as Txt from '../../styles/text'
import { AtividadesCard } from '../../components/AdminTurmaCard/styles'

const TurmaDetalhes = () => {
  const { id } = useParams()
  const [turma, setTurma] = useState<Turmas | null>(null)

  useEffect(() => {
    fetch(`http://localhost:8000/api/turmas/${id}/`)
      .then((response) => response.json())
      .then((data) => setTurma(data))
  }, [id])

  if (!turma) return <div>Carregando...</div>

  return (
    <>
      <Header />
      <Menu />
      <div className="p-4">
        <S.CardDetalhes className="container p-4 rounded-4 mt-4">
          <S.DetalhesTitle>{turma.nome_turma}</S.DetalhesTitle>
          <img src={turma.imagem} alt="" className="w-100 rounded-3 mt-3" />
          <p className="text-center mt-3 fs-5">{turma.objetivo}</p>
          <h5 className="text-center mt-4 fw-bold">
            O que será desenvolvido na turma:
          </h5>
          <p className="text-center mt-3 fs-5">{turma.desenvolver}</p>
          <h3 className="text-center mt-4 mb-3 fw-bold">
            Atividades propostas:
          </h3>
          <ul className="ps-0">
            {turma.atividades.map((atividade) => (
              <li className="" key={atividade.id}>
                <AtividadesCard>
                  <Txt.TitleMd className="text-center fw-bold">
                    {atividade.titulo_ativ}
                  </Txt.TitleMd>
                  <p className="text-center mt-2">{atividade.desc_ativ}</p>
                </AtividadesCard>
              </li>
            ))}
          </ul>
        </S.CardDetalhes>
      </div>
    </>
  )
}

export default TurmaDetalhes
