import { useNavigate } from 'react-router-dom'

import FormVaga from '../FormVaga'
import * as Txt from '../../styles/text'
import * as S from './styles'

interface Props {
  id: string
  nome_vaga: string
  desc_vaga: string
  req_vaga: string
  salario: string
  created_at: string
  disponivel: boolean
}

const ModalVaga = ({
  id,
  nome_vaga,
  desc_vaga,
  req_vaga,
  salario,
  created_at,
  disponivel
}: Props) => {
  const navigate = useNavigate()

  return (
    <>
      <div
        className="modal fade"
        id={`staticBackdrop-${nome_vaga.replace(/\s/g, '')}`}
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby={`staticBackdropLabel-${nome_vaga.replace(/\s/g, '')}`}
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="staticBackdropLabel">
                {nome_vaga}
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <Txt.TextMd>{desc_vaga}</Txt.TextMd>
              <Txt.TitleMd>Requisitos</Txt.TitleMd>
              <Txt.TextMd>{req_vaga}</Txt.TextMd>
              <Txt.TitleMd>Salário</Txt.TitleMd>
              <Txt.TextMd>{salario}</Txt.TextMd>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Fechar
              </button>
              <button
                type="button"
                className="btn btn-success"
                data-bs-target={`#staticBackdrop${nome_vaga.replace(/\s/g, '')}2`}
                data-bs-toggle="modal"
              >
                Aplicar à vaga
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id={`staticBackdrop${nome_vaga.replace(/\s/g, '')}2`}
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby={`staticBackdrop${nome_vaga.replace(/\s/g, '')}2`}
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="staticBackdropLabel">
                Formulário de inscrição
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <Txt.TextMd>
                Preencha o formulário abaixo para cadastrar seus dados de
                contato, e vaga de preferência.
              </Txt.TextMd>
              <FormVaga nome_vaga={nome_vaga} />
            </div>
            {/* <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Cancelar
              </button>
              <button
                type="button"
                className="btn btn-success"
                data-bs-target={`#staticBackdrop${nome_vaga.replace(/\s/g, '')}-success`}
                data-bs-toggle="modal"
              >
                Enviar
              </button>
            </div> */}
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id={`staticBackdrop${nome_vaga.replace(/\s/g, '')}-success`}
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby={`staticBackdrop${nome_vaga.replace(/\s/g, '')}-success`}
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <S.ModalSuccess className="modal-content">
            <div className="modal-body p-4">
              <img
                src="https://static-file-vault.vercel.app/cdtchechela/modal_success.svg"
                alt=""
              />
              <S.TextContainer>
                <Txt.TitleBig>Obrigado por se cadastrar!</Txt.TitleBig>
                <Txt.TextMd>
                  Ficamos muito felizes em saber que você tem interesse em fazer
                  parte da nossa equipe.
                </Txt.TextMd>
                <Txt.TextMd>
                  Alguma outra mensagem, mas dessa vez sobre o processo seletivo
                  ou algo do tipo.
                </Txt.TextMd>
              </S.TextContainer>
              <button
                className="btn btn-warning p-3 mb-3"
                data-bs-dismiss="modal"
                onClick={() => navigate('/dev')}
              >
                Voltar à página inicial
              </button>
            </div>
          </S.ModalSuccess>
        </div>
      </div>
    </>
  )
}

export default ModalVaga
